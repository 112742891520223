<template>
    <div>
        <layout-header title=" " btn-left="back"></layout-header>

        <div class="wrap p-email-join">
            <div class="container">
                <div class="content">
                    <div class="img-wrap">
                        <img src="/assets/images/member/img_email_join.png" alt="">
                    </div>
                    <div class="join-form">
                        <template v-if="authing">
                            <div class="page-title">
                                <h2 class="tit">이메일 인증 진행중</h2>
                                <p class="sub-tit">이메일 인증이 진행중입니다. 잠시만 기다려 주세요.</p>
                            </div>
                            <div class="btn-area">
                                <button type="button" class="btn-member" @click="doSendAuth" style="margin: 32px 25%">재발송</button>
                            </div>
                        </template>

                        <template v-else>

                            <template v-if="success">
                                <div class="page-title">
                                    <h2 class="tit">이메일 인증 완료</h2>
                                    <p class="sub-tit">회원가입이 완료되었습니다. <br>로그인 하시면 마샤와 곰의 서비스 이용이 가능합니다.</p>
                                </div>
                                <div class="btn-area">
                                    <button href="button" class="btn-member" @click="resetLogin" style="margin: 32px 25%">로그인</button>
                                </div>
                            </template>

                            <template v-else>
                                <div class="page-title">
                                    <h2 class="tit">이메일 인증 실패</h2>
                                    <p class="sub-tit">인증키가 맞지 않아 인증이 취소되었습니다.<br>인증키 메일을 재발송 하여 재인증을 시도하세요.</p>
                                </div>
                                <div class="btn-area">
                                    <button type="button" class="btn-member" @click="doSendAuth" style="margin: 32px 25%">재발송</button>
                                </div>
                            </template>

                        </template>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";

export default {
    name: "emailJoinAuth",
    components: {LayoutHeader},
    data () {

        let caidx = this.webUtils.getParameter('caidx')
        let to = this.webUtils.getParameter('to')
        let authString = this.webUtils.getParameter('authString')

        return {
            authing: true,
            success: false,

            caidx,
            to,
            authString,
            coAccountVO: {}
        }
    },
    created() {

    },
    mounted() {
        this.commonUtils.localLog(this.to)
        this.commonUtils.localLog(this.authString)
        this.doAuthing()
    },
    methods: {

        doAuthing () {
            if (!this.commonUtils.nullCheck(this.to) && !this.commonUtils.nullCheck(this.authString)) {

                this.$eventBus.$emit("startProgress")
                this.axiosCaller.post( this, this.APIs.CO_ACCOUNT + "/doAuthing", {
                    email: this.to,
                    authString: this.authString,
                }, (res) => {

                    this.$eventBus.$emit("doneProgress")

                    let result = res.data
                    this.authing = false
                    this.success = result.success
                })

            }
        },



        doSendAuth () {

            this.$eventBus.$emit("startProgress", 2)
            this.axiosCaller.post( this, this.APIs.CO_ACCOUNT + "/doSendAuth/" + this.caidx, {
                caidx: this.caidx
            }, (res) => {
                const result = res.data
                if ( result.status === "ok" ) {
                    this.swalUtils.gritter("인증메일 발송", "인증메일이 발송되었습니다. <br>메일 링크를 클릭하여 가입을 완료해주세요.", "info")
                }
                this.$eventBus.$emit("doneProgress", 2)
            })
        },

    }

}
</script>

<style scoped>

</style>